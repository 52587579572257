import LanguageIdentifier from "components/languageIdentifier";
import Layout from "components/layout";
import React from "react";
import Seo from "components/seo";
import TopicdetectorTool from "components/languageIdentifier/topicdetector";
import ToolFaq from "components/QualityTools/ToolFaq";
import { TopicDetectionToolFaqData } from "components/FAQ/config";

const index = () => {
  return (
    <Layout>
      <Seo
        title="Topic Detection Tool"
        description="Our topic identification tool will detect the main topic or subject field of your text, ensuring it's matched with a translator with the right expertise."
        slug="/tools/topic-detection"
      />
      <TopicdetectorTool />
      <ToolFaq QaToolFaqData={TopicDetectionToolFaqData} />
    </Layout>
  );
};

export default index;
